import { useEffect, useState } from 'react';
import { getLocationById, likeLocation, unlikeLocation } from '../../backendclient';
import Typography from '@mui/material/Typography';
import { IconButton, Tooltip } from '@mui/material';
import { Marker, Popup } from 'react-leaflet'
import { useLocation, useNavigate, useParams } from 'react-router';
import Map from '../Map';
import { CenterFocusWeak, Edit, Group } from '@mui/icons-material';
import './LocationPage.css';
import { defaultIcon, locationTypeIcons } from '../../markerIcons';
import ApplicationBar from '../ApplicationBar';
import Linkify from 'react-linkify/dist/components/Linkify';
import LocationImages from '../LocationImages/LocationImages';
import LocationFiles from '../LocationFiles/LocationFiles';
import DisplayWithPrivilege from '../DisplayWithPrivilege';
import IsoDate from '../IsoDate/IsoDate';
import CommentDisplay from '../CommentDisplay';
import InfoFooter from '../InfoFooter';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import LocationSharingModal from './LocationSharingModal';
import LocationCollectionModal from '../LocationCollectionModal';
import LocationCollectionModalButton from '../LocationCollectionModal/LocationCollectionModalButton';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import LocationsContext from '../../contexts/LocationsContext';
import LikeButton from '../LikeButton';
import UsernameLink from '../UsernameLink';
import LocationRating from '../LocationRating';

function LocationPage({currentUser, reloadLocations}) {
    const [location, setLocation] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const reactRouterLocation = useLocation();
    const params = useParams();
    const locationId = params.locationId;
    const [sharingModalOpen, setSharingModalOpen] = useState(false);
    const [collectionModalOpen, setCollectionModalOpen] = useState(false);
    const [refreshToken, setRefreshToken] = useState(0);

    useEffect(() => {
        getLocationById(locationId)
            .then(setLocation)
            .catch(e => {
                if (e.response && e.response.status === 401) {
                    navigate(`/login?redirect=${reactRouterLocation.pathname}`)
                } else {
                    setError(e);
                }
            });
    }, [locationId, navigate, reactRouterLocation, refreshToken]);

    useDocumentTitle(location ? `${location.name} - Gold Hills Trading Post` : "Gold Hills Trading Post");

    if (error) {
        return <Typography color="error">{error.message}</Typography>
    }

    if (!location) {
        return <Typography>Loading...</Typography>
    }

    function renderField(field) {
        if (!field.value || field.value === "Unknown") {
            return null;
        }

        let displayValue = field.value;
        if (field.unit) {
            displayValue += ` ${field.unit}`;
        }

        return <div key={field.id} className='PageSection'>
            <Typography variant="h6">{field.name}</Typography>
            <Linkify><Typography className='SectionText' variant="body2">{displayValue}</Typography></Linkify>
        </div>
    }

    function getVisibilityLevelDisplayValue() {
        if (location.isSensitive) {
            return "Sensitive";
        } else if (location.isPrivate) {
            return "Private";
        } else {
            return "Open";
        }
    }

    return (
        <div>
            <ApplicationBar />
            
            <div className='Container'>
                <div className='LocationPageTitleSection'>
                    <Typography className='Title' variant="h4">{location.name}</Typography>
                    <div className='TitleButtons'>
                        <Tooltip title="View on map">
                            <IconButton onClick={() => navigate(`/map?lat=${location.lat}&lng=${location.lng}`)} ><CenterFocusWeak /></IconButton>
                        </Tooltip>
                        <LikeButton 
                            likedByUsers={location.likedByUsers} 
                            likeFunc={async () => await likeLocation(locationId)} 
                            unlikeFunc={async () => await unlikeLocation(locationId)} 
                            onChange={() => setRefreshToken(x => x+1)}
                            tooltipResourceName="Location"
                        />
                        <LocationCollectionModalButton onClick={() => setCollectionModalOpen(true)} />
                        <DisplayWithPrivilege privilege="canEditLocations" >
                            <Tooltip title="Share Location"><IconButton className='ShareButton' onClick={() => setSharingModalOpen(true)}><Group /></IconButton></Tooltip>
                            <Tooltip title="Edit Location"><IconButton className='EditButton' onClick={() => navigate(`/edit/${location.id}`)}><Edit /></IconButton></Tooltip>
                        </DisplayWithPrivilege>
                    </div>
                </div>
                <Typography variant="subtitle1">{location.type}</Typography>

                <LocationImages readOnly locationId={locationId} className="PageSection" />
                
                {Object.values(location.fields).map(renderField)}

                <div className='PageSection'>
                    <Typography variant="body2">Date Added: <IsoDate isoString={location.createdAt} /></Typography>
                    <Typography variant="body2">Date Last Modified: <IsoDate isoString={location.updatedAt} /></Typography>
                    <Typography variant="body2">Created By: {location.creator ? <UsernameLink user={location.creator} /> : "Unknown"}</Typography>
                    <Typography variant="body2">Location Visibility: {getVisibilityLevelDisplayValue()}</Typography>
                    <Typography variant="body2">View Count: {location.viewCount ? location.viewCount : 0}</Typography>
                    
                    <LocationRating location={location} verbose style={{marginTop: "10px"}} />
                </div>

                <Map style={{height: 400}} center={[location.lat, location.lng]} zoom={15}>
                    <Marker position={[location.lat, location.lng]} icon={locationTypeIcons[location.type] || defaultIcon}>
                        <Popup keepInView={false} autoPan={false}>{location.name}</Popup>
                    </Marker>
                </Map>
                <div className="LocationMeta">
                    <Typography variant="caption">Lat:{location.lat} Lng:{location.lng}</Typography>
                    <Typography variant="caption" style={{marginLeft: "auto"}}><a href={`https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`}>Directions</a></Typography>
                </div>

                <LocationFiles className="PageSection" locationId={locationId} canEdit={false} />

                <CommentDisplay locationId={locationId} />

                <LocationSharingModal open={sharingModalOpen} onClose={() => setSharingModalOpen(false)} locationId={locationId} />
                <LocationCollectionModal open={collectionModalOpen} onClose={() => {
                    setCollectionModalOpen(false);
                    reloadLocations(); // reload locations because we might be viewing a location that we modified
                }} locationId={locationId} />
            </div>

            <InfoFooter />
        </div>
    );
}

const LocationPageWrapper = (props) => <CurrentUserContext.Consumer>
    {currentUser => <LocationsContext.Consumer>
        {locationsContext => <LocationPage reloadLocations={locationsContext.reloadLocations} currentUser={currentUser} {...props} />}
    </LocationsContext.Consumer>}
</CurrentUserContext.Consumer>;;

export default LocationPageWrapper;
