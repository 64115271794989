import { useEffect, useState } from 'react';
import './SearchDisplay.css';
import { searchLocations, getUsers } from '../../backendclient.js';
import LocationImagePreview from '../LocationImagePreview';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import LocationRating from '../LocationRating/index.js';

async function searchUsers(searchString) {
    if (!searchString) {
        return [];
    }
    const users = await getUsers();
    return users.filter(u => u.username?.toLowerCase().includes(searchString.toLowerCase())).slice(0, 5);
}

function LocationSearchResult({location}) {
    const navigate = useNavigate();

    return <div className='SearchResult'  onClick={() => navigate(`/location/${location.id}`)}>
        <div>
            <div><Typography variant='body1'>{location.name}</Typography></div>
            <div><Typography variant='caption'>Type: {location.type}</Typography></div>
            <LocationRating location={location} readOnly />
        </div>
        <LocationImagePreview className="SearchResultImage" locationId={location.id} />
    </div>
}

function renderLocationResults(locationResults) {
    if (!locationResults || locationResults.length === 0) {
        return null;
    }

    return <div className='SearchResultSection'>
        <div className='SearchResultSectionTitle'>Locations</div>
        {locationResults.map(l => <LocationSearchResult key={l.id} location={l} />)}
    </div>
}

function UserSearchResult({user}) {
    const navigate = useNavigate();

    return <div className='SearchResult' onClick={() => navigate(`/user/${user.id}`)}>
        <div>
            <div><Typography variant='body1'>{user.username}</Typography></div>
        </div>
    </div>
}

function renderUserResults(userResults) {
    if (!userResults || userResults.length === 0) {
        return null;
    }

    return <div className='SearchResultSection'>
        <div className='SearchResultSectionTitle'>Users</div>
        {userResults.map(u => <UserSearchResult key={u.id} user={u} />)}
    </div>
}

function SearchDisplay({searchString, mapConfig, onChange}) {
    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);
    const [effectiveMapConfig, setEffectiveMapConfig] = useState(mapConfig);
    const [effectiveSearchString, setEffectiveSearchString] = useState(searchString);

    useEffect(() => {
        if (effectiveSearchString !== searchString) {
            setEffectiveMapConfig(mapConfig);
            setEffectiveSearchString(searchString);
        }
    }, [mapConfig, searchString, effectiveSearchString])

    useEffect(() => {
        async function search() {
            try {
                const locationsPromise = searchLocations(effectiveSearchString, effectiveMapConfig?.mapCenter);
                const usersPromise = searchUsers(effectiveSearchString);
                setResults({
                    locations: await locationsPromise,
                    users: await usersPromise,
                });
            } catch(e) {
                console.log(e);
                setError(e);
            }
        }

        setError(null);
        setResults(null);

        if (effectiveSearchString) {
            search();
        }
    }, [effectiveSearchString, effectiveMapConfig?.mapCenter]);

    useEffect(() => {
        if (onChange) {
            onChange(results);
        }
    }, [results, onChange]);
    
    if (!effectiveSearchString) {
        return null;
    }

    if (!results) {
        return <div className='SearchDisplay'>
            Loading...
        </div>
    }

    return (
        <div className='SearchDisplay'>
            {error ? error.message : null}
            {renderLocationResults(results.locations)}
            {renderUserResults(results.users)}
            {(!results.locations || results.locations.length === 0) && (!results.users || results.users.length === 0) ? <div className='SearchResultSectionTitle'>No results...</div> : null}
        </div>
    );
}

export default SearchDisplay;
