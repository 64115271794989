import { Marker, Popup } from 'react-leaflet'
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { defaultIcon, locationTypeIcons } from '../../markerIcons';
import LocationImagePreview from '../LocationImagePreview';
import LocationRating from '../LocationRating';

function LocationMarker({location}) {
    return <Marker 
            position={[location.lat, location.lng]} 
            key={location.id}
            icon={locationTypeIcons[location.type] || defaultIcon}
        >
        <Popup keepInView={false} autoPan={false} className='LocationLeafletPopup'>
            <Link to={`/location/${location.id}`}>
                <LocationImagePreview locationId={location.id} />
                <Typography variant="subtitle1">{location.name}</Typography>
            </Link>
            <Typography variant="body2">Type: {location.type}</Typography>
            {Object.values(location.fields).map(field => 
                ["choice", "number"].includes(field.type) && field.value && field.value !== "Unknown" ? <Typography key={field.id + field.name} variant="body2">{field.name}: {field.unit ? field.value + " " + field.unit : field.value}</Typography> 
            : null)}
            <LocationRating readOnly location={location} />
        </Popup>
    </Marker>
}

export default LocationMarker;
